import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-awesome-styled-grid"

import PostCard from "../components/card"
import Layout from "../components/layout"
import {MariaPrimary, MariaSecondary} from "../utils/colors"
import SEO from "../components/seo"

class Blog extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = "Blog"
    const posts = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Blog" />
        <Container style={{margin: "0 0 5rem 0"}}>
          <Row justify='space-around'>
            {posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug

              return (
                <Col xs={6} sm={4} md={2.65} align='justify' style={{display: `flex`, flexDirection: `column`, textAlign: `center`}}>
                  <Link
                    style={{ boxShadow: `none`, color: MariaPrimary }}
                    to={`blog${node.fields.slug}`}
                  >
                    <PostCard>
                        <div key={node.fields.slug} style={{height: `100%`, position: `relative`, overflow: `hidden`}}>
                          <Img style={{height: `100%`, width: `100%`, position: `absolute`}} fluid={node.frontmatter.thumbnail.childImageSharp.fluid}/>
                          <div style={{position: `relative`, height: `100%`, zIndex: `2`, margin: 0, padding: 0}}>
                          <h4 style={{WebkitTransform: `translate(-50%, 0)`, position: `absolute`, top: `30%`, left: `50%`, padding:`0 1rem`}}>
                              {title}
                          </h4>
                          <small style={{WebkitTransform: `translate(-50%, 0)`, position: `absolute`, top: `80%`, left: `50%`, fontFamily: `"Press Start 2P"`, fontSize: `0.45rem`, color: MariaPrimary, background: MariaSecondary, padding: `0.2rem 0.3rem`, fontWeight: `bolder`, borderRadius: `5px`}}>{node.frontmatter.date}</small>
                          </div>
                        </div>
                      </PostCard>
                    </Link>
                </Col>
              )
            })}
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  allMdx(filter: {frontmatter: {collection: {eq: "blog-post"}}}, sort: {fields: frontmatter___date, order: DESC}) {
    edges {
      node {
        frontmatter {
          collection
          tags
          thumbnail {
            childImageSharp {
              fluid(
                duotone: { highlight: "#AD316C", shadow: "#251863", opacity: 10 }
                traceSVG: {
                  color: "#251863"
                  turnPolicy: TURNPOLICY_MINORITY
                  blackOnWhite: false
                }
                toFormat: PNG
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          title
          date(formatString: "DD MMM, YYYY")
        }
        fields {
          slug
        }
      }
    }
  }
}
`
